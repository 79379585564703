<template>
  <v-dialog
    v-model="viewSalesOrderMenu[item.order_id]"
    :key="item.order_id"
    max-width="450"
  >
    <v-card class="mx-auto" tile>
      <v-card-title>
        {{ item.order_number }} <v-spacer></v-spacer>
        <v-btn small right icon @click="passMenuClose($event)">
          <v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-list desnse>
        <!---->
        <v-list-item-group>
          <template v-for="item in items">
            <v-list-item
              :key="item.text"
              @click="item.action"
              v-if="item.condition"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <!--Generate invoice-->
          <v-list-item
            :key="'generate-invoice'"
            @click="!isCreatingInvoice && generateXeroInvoice()"
            v-if="item.order_status === 'Completed' && !item.xeroInvoiceId"
          >
            <v-list-item-icon>
              <v-icon v-if="!isCreatingInvoice">mdi-invoice</v-icon>
              <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ isCreatingInvoice ? 'Generating Invoice...' : 'Generate Customer Invoice' }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <!---->

        <!--Tag Dialog-->
        <v-dialog v-model="tag_dialog" max-width="650">
          <v-card class="py-5">
            <v-card-title class="text-h5 mb-5"> Select Tags </v-card-title>
            <v-card-text>
              <v-chip-group v-model="selected_tags" column multiple>
                <v-chip
                  v-for="tag in tags"
                  :key="tag.name"
                  class="mr-2 mb-3"
                  filter
                  active-class="success"
                  @click="addTagToSelectedGroup(tag)"
                >
                  <v-icon left small>
                    {{ tag.icon }}
                  </v-icon>
                  {{ tag.name }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-actions class="mt-3">
              <v-spacer></v-spacer>
              <v-btn text @click="tag_dialog = false"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!--Image Upload-->
        <input
          type="file"
          ref="imageUpload"
          @change="attachImage()"
          style="display: none"
          accept="image/*"
        />
        <!--Attachments View Modal-->
        <ViewAttachmentsModal
          :ref="item.order_id"
          :item="item"
          :attachments_dialog="attachments_dialog"
        />
      </v-list>
      <!-- Quick assign branch -->
      <v-dialog v-model="branch_dialog" max-width="650">
          <v-card class="py-5">
            <v-card-title class="text-h5 mb-5"> Assign a branch </v-card-title>
            <v-card-text>
              <v-chip-group v-model="selected_branch" column >
                <v-chip
                  v-for="branch in branches"
                  :key="branch.branch_id"
                  class="mr-2 mb-3"
                  filter
                  active-class="success"
                  @click="quickAssignBranch(item, branch)"
                >
                  
                  {{ branch.branch_name }}
                </v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-actions class="mt-3">
              <v-spacer></v-spacer>
              <v-btn text @click="branch_dialog = false"> Cancel </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-card>
  </v-dialog>
</template>
<script>
import db from "../../../components/firebaseInit";
import axios from "axios";
import { generateSalesPDF } from "../data/external_print_sales_order";
import mixinCompanyProfile from "../../../globalActions/mixin_CompanyProfile";
import mixin_tags from "../mixins/mixin_tags";
export default {
  name: "SalesOrdersTableOrderActionsMenu",
  props: ["item", "viewSalesOrderMenu", "user_roles_list", "branches"],
  components: {
    ViewAttachmentsModal: () => import("./Modals/ViewAttachmentsModal.vue"),
  },
  mixins: [mixinCompanyProfile, mixin_tags],
  data() {
    return {
      tag_dialog: false,
      branch_dialog: false,
      attachments_dialog: {},
      selected_tags: this.selected_tags,
      selected_branch: null,
      isCreatingInvoice: false,
      items: [
        {
          text: "Edit Order",
          icon: "mdi-pencil",
          condition: this.getEditOrderStatus(this.item),
          action: () =>
            this.$router.push({
              name: "edit-order",
              params: { order_id: this.item.order_id },
            }),
        },
        {
          text: "View Order",
          icon: "mdi-eye",
          condition: true,
          action: () =>
            this.$router.push({
              name: "view-order",
              params: { order_id: this.item.order_id },
            }),
        },
        {
          text: `Download ${this.getOrderStatus()}`,
          icon: "mdi-file-download",
          condition: true,
          action: () =>
            this.generateSalesPDF(this.getOrderStatus(), this.item, "this"),
        },
        // View attached image file
        {
          text: "View Attachments",
          icon: "mdi-file-eye-outline",
          condition: true,
          action: () => {
            this.$set(this.attachments_dialog, this.item.order_id, true);
          },
        },
        { text: "Set as Completed", icon: "mdi-check" },
        //  Work Orders
        {
          text: "Create Work Order",
          icon: "mdi-clipboard",
          condition: this.getProductionStatus(this.item),
          action: () =>
            this.$router.push({ path: `new-work-order/${this.item.order_id}` }),
        },
        {
          text: "View Work Order",
          icon: "mdi-file-eye",
          condition: !this.getProductionStatus(this.item),
          action: () =>
            this.$router.push({
              path: `view-work-order/${this.item.work_order_id}`,
            }),
        },
        //  Payments
        {
          text: "View Payments",
          icon: "mdi-cash-multiple",
          condition: this.getPaymentStatus(this.item),
          action: () => alert(""),
        },
        //  Communication
        {
          text: "Email Customer",
          icon: "mdi-email",
          condition: this.getCustomerEmailStatus(this.item),
          action: () => alert(""),
        },
        {
          text: "WhatsApp Customer",
          icon: "mdi-whatsapp",
          condition: this.getCustomerWhatsAppStatus(this.item),
          action: () => alert(""),
        },
        {
          text: "Add Tags To Order",
          icon: "mdi-tag-multiple",
          condition: true,
          action: () => (this.tag_dialog = true),
        },
        {
          text: "Quick Assign Branch",
          icon: "mdi-store",
          condition: this.item.order_branch === undefined,
          action: () => this.branch_dialog = true,
        },
        {
          text: "Edit Customer Details",
          icon: "mdi-account-edit",
          condition: this.getCustomerEditStatus(),
          action: () =>
            this.$router.push({
              path: `edit-customer/${this.item.customer.customer_id}`,
            }),
        },
      ],
    };
  },
  methods: {
    generateSalesPDF,
    getOrderStatus() {
      return this.item.order_status === "Quote"
        ? "Quotation"
        : "Pro-Forma Invoice";
    },
    getProductionStatus(order) {
      return order.work_order_production_status === "Not Started" &&
        this.user_roles_list.flat().includes("work_orders_write")
        ? true
        : false;
    },
    getEditOrderStatus() {
      return this.user_roles_list.flat().includes("sales_write");
    },
    getCustomerEmailStatus(order) {
      return order.customer.customer_email !== null ? true : false;
    },
    getCustomerWhatsAppStatus(order) {
      return order.customer.customer_cell !== null ? true : false;
    },
    getCustomerEditStatus() {
      return this.user_roles_list.flat().includes("customers_write")
        ? true
        : false;
    },
    getPaymentStatus(order) {
      return this.user_roles_list.flat().includes("banking_read") &&
        order.customer_payment_allocation_id !== null
        ? true
        : false;
    },
    passMenuClose() {
      this.$set(this.viewSalesOrderMenu, this.item.order_id, false);
    },
    async quickAssignBranch({ order_id }, branch) {
      
      const salesOrderRef = db.collection('sales_orders').doc(order_id);

      try {
          await salesOrderRef.update({
              order_branch: branch
          });
          
      } catch (error) {
          console.error('Error updating sales order branch:', error);
      } finally {
        this.branch_dialog = false
      }
    },
    async generateXeroInvoice() {
      this.isCreatingInvoice = true;  // Set loading state to true
      const url = "https://us-central1-vue-order-fulfillment.cloudfunctions.net/createXeroInvoice";
      try {
          const response = await axios.post(url, { order_id: this.item.order_id });
          console.log('Invoice created:', response.data);
          this.$emit('invoice-created', response.data);
      } catch (error) {
          console.error('Error creating invoice:', error);
          alert('Failed to create invoice: ' + (error.response ? error.response.data : error.message));
      } finally {
          this.passMenuClose();
          this.isCreatingInvoice = false;  // Reset loading state
      }
    },
    // TAGS
    //_______________________________
    addTagToSelectedGroup(tag) {
      // change selected tag toggle value
      tag.toggled = !tag.toggled;
      this.selected_tags = this.tags.filter((tag) => tag.toggled === true);
      const orderRef = db.collection("sales_orders").doc(this.item.order_id);
      orderRef
        .update({
          selected_tags: this.selected_tags,
        })
        .then(() => {
          console.log("Tags successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
